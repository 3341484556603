let resumeData = {
    "imagebaseurl":"https://github.com/numankh",
    "name": "Numan Khan",
    "role": "Full-Stack Engineer and Data Scientist",
    "linkedinId":"numan-khan",
    "skypeid": "Your skypeid",
    "roleDescription": "I'm a software engineer specializing in back-end development and data science. Currently, I'm focused on helping people optimize their smart home devices at ",
    "socialLinks":[
        {
          "name":"linkedin",
          "url":"https://www.linkedin.com/in/numan-khan/",
          "className":"fa fa-linkedin"
        },
        {
          "name":"github",
          "url":"https://github.com/numankh",
          "className":"fa fa-github"
        },
        // {
        //   "name":"skype",
        //   "url":"http://twitter.com/rbhatia46",
        //   "className":"fa fa-twitter"
        // }
      ],
    "aboutme":"Hello! My name is Numan and I enjoy creating things that live on the internet. My interest in coding started back in 2018 when I competed in a coding challenge. I analyzed public data from the San Francisco Fire Department that contained dispatch information for emergency calls which taught me a lot about different statistical Python packages. ",
    "aboutme2":"Fast-forward to today, and I’ve had the privilege of working at a mortgage loan company, financial institution, and a large corporation. My main focus these days is helping people optimize their smart home devices at Amazon.",
    "address":"Virginia",
    "website":"https://numank.com/",
    "education":[
      {
        "UniversityName":"Virginia Tech",
        "specialization":"BS in Computer Science",
        "MonthOfPassing":"Aug",
        "YearOfPassing":"2020",
        "Coursework":"Coursework: Machine Learning, Data Science Senior Capstone, Data Analytics & Visualization, Data Structures and Algorithms, Operating Systems",
        "Achievements":"Awards: Computer Science Resources Consortium Scholarship, Pratt Engineering Scholarship, 1st Place for Industry Marston Entrepreneurial & 1st Place for People’s Choice at VTURCS Spring Symposium 2019, Semi-Finalist of 2020 VT Entrepreneurship Challenge"
      }
    ],
    "work":[
      {
        "CompanyName":"Amazon",
        "specialization":"Software Development Engineer",
        "MonthOfLeaving":"Present",
        "YearOfLeaving":"",
        "Achievements":"Backend development for an Alexa service where customers enroll devices that can monitor and reorder household supplies using Java and AWS"
      },
      {
        "CompanyName":"Capital One",
        "specialization":"Data Engineer Intern",
        "MonthOfLeaving":"Aug",
        "YearOfLeaving":"2019",
        "Achievements":"Built AWS Lambda functions that sent event data from a data lake to a PostgreSQL database on AmazonRDS using Python and Serverless framework"
      },
      {
        "CompanyName":"Freddie Mac",
        "specialization":"Technology Intern",
        "MonthOfLeaving":"Aug",
        "YearOfLeaving":"2018",
        "Achievements":"Created a customized internal tool that identifies differences between DDL files in development and production environments for database migrations using Java, Unix, & KornShell"
      }
    ],
    "skillsDescription":"Languages: Python, Java, Bash, C++, C; Databases: SQL, DynamoDB, PostgreSQL, RDS, MongoDB; Web Development: JavaScript, TypeScript, React, HTML/CSS; Technology: Git, AWS, GCP, Docker",
    "skillsLang":"Languages: Python, Java, Bash, C++, C",
    "skillsDb":"Databases: SQL, DynamoDB, PostgreSQL, RDS, MongoDB",
    "skillsWeb":"Web Development: JavaScript, TypeScript, React, Express, Node, HTML/CSS",
    "skillsTech":"Technology: Git, AWS, GCP, Docker",
    "skills":[
      {
        "skillname":"HTML5"
      },
      {
        "skillname":"CSS"
      },
      {
        "skillname":"Reactjs"
      }
    ],
    "portfolio":[
      {
        "name":"HypeBeast Dashboard",
        "description":"Ebay shoe resale market analytics dashboard",
        "imgurl":"images/portfolio/phone.jpg",
        "githuburl":"https://github.com/numankh/HypeBeastDashboard"
      },
      {
        "name":"UrNews",
        "description":"A web app for users to view unbias news about popular topics",
        "imgurl":"images/portfolio/project.jpg",
        "githuburl":"https://github.com/numankh/UrNews",
        "url":"https://ur-news.herokuapp.com/health"
      },
      {
        "name":"National Park Planner",
        "description":"Cloud Software Development final project",  
        "imgurl":"images/portfolio/project2.png",
        "githuburl":"https://github.com/numankh/NationalParks"
      },
      // {
      //   "name":"GRE Vocab Helper",
      //   "description":"Web service that helps the user study common GRE vocab terms",  
      //   "imgurl":"images/portfolio/project2.png",
      //   "githuburl":"https://github.com/numankh/GRE-Vocab-Helper"
      // },
    ]
  }
  
  export default resumeData