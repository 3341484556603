import React, { Component } from 'react';
import Card from 'react-bootstrap/Card'

export default class Experience extends Component {
  render() {
    let resumeData = this.props.resumeData;
    return (
      <section id="portfolio">
      <div className="row">
        <div className="twelve columns collapsed">
          <h1>Check Out Some of My Works.</h1>

          <div class="row">
          {
            resumeData.portfolio && resumeData.portfolio.map((item)=>{
              return(
                <div class="column">
                <Card style={{ width: '30rem' }}>
                  <Card.Body>
                    <Card.Link href={item.githuburl} target="_blank" rel="noopener noreferrer">{item.name}</Card.Link>
                    {/* <Card.Subtitle className="mb-2 text-muted">{}</Card.Subtitle> */}
                    <Card.Text>
                      {item.description}
                    </Card.Text>
                    {/* <Card.Link href={item.githuburl}>Github</Card.Link> */}
                    {/* <Card.Link href="#">Another Link</Card.Link> */}
                  </Card.Body>
                </Card>
                </div>
              )
            })
          }
          </div>
        </div>
      </div>
  </section>
        );
  }
}